/* .addclass{
    position: fixed !important;
    position: absolute;
    top: 0;

} */

.addclass {
    background-color: aliceblue;
    height: 10vh;
    padding-top: 15px;
    color:#184A45FF ;
    position: fixed;
    top: 0; /* Fixed position from the top */
    left: 0; /* Fixed position from the left */
    width: 100%; /* Take full width of the viewport */
    z-index: 1000; /* Set a high z-index to ensure it's above other elements */
  }
 .frm{
    margin-top: 100px;
    /* background-color: black; */

  }
 .btn1{
  width: 10%;
  color: white;
  margin: 1%;
  margin-left: 0px;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
 }
  
 body{
  background-color: aliceblue;
 }
.btn2{
  position: relative;
  left: -47%;
}
.datetime-input {
  /* Add your styling here */
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
  margin-top: 5px;
  /* Add any other styles you want */
}