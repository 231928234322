/* Dashboard.css */

.custom-table-new {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    overflow-x: auto;
  }
  
  .custom-table-new th, .custom-table-new td {
    border: 2px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .custom-table-new thead th {
    background-color: #f2f2f2;
  }
  
  .custom-table-new tbody tr:hover {
    background-color: #f5f5f5;
  }
  .noData{
    margin-top: 150px;
  }

  .imgg{
    height: 250px;
    width: 250px;
  }
  .dark-green{
    color: darkgreen;
  }
  .history{
    margin-left: 2% ;
    background-color:#184A45FF;
    --bs-btn-color: #fff !important;
    --bs-btn-bg:rgb(7, 150, 136) !important;
    --bs-btn-border-color:rgb(7, 150, 136) !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg:rgb(7, 150, 136) !important;
    --bs-btn-hover-border-color:rgb(7, 150, 136) !important;
    --bs-btn-focus-shadow-rgb: 225,83,97;
    --bs-btn-active-color:rgb(7, 150, 136) !important;
    --bs-btn-active-bg:rgb(7, 150, 136) !important;
    --bs-btn-active-border-color:rgb(7, 150, 136) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color:rgb(7, 150, 136) !important;
    --bs-btn-disabled-bg:rgb(7, 150, 136) !important;
    --bs-btn-disabled-border-color:rgb(7, 150, 136) !important;

  }


  .custom-date-column {
    /* Remove fixed width or set it to auto */
    width: auto !important;
    /* Add some padding to the date cells */
    padding: 8px !important;
}
.custom-table-new td.custom-date-column {
  white-space: nowrap;
  width: auto !important;
  padding: 8px !important; /* Adjust padding as needed */
}
  h1{
   
   /* background-color: black; */
   text-align: center;
   padding: 1%;
   color: #184A45FF;
  }


.logout{
  /* background-color: black; */
  position: relative;
  margin-left: 100%;
  top: -10%;
  position: sticky;
}


/* Dashboard.css */

/* ... Your existing styles ... */

/* Additional styles for modal */
.modal-content {
  padding: 20px;
}

.modal-header {
  background-color: #184A45FF;
  color: white;
}

.modal-footer {
  background-color: #f2f2f2;
}

/* Styling for modal buttons */
.modal-footer button {
  color: white;
  background-color: #184A45FF;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.modal-footer button:hover {
  background-color: #0d6efd;
}

/* Additional styles for centering modal */
.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* Additional styles for overlay background */
.modal-backdrop.show {
  opacity: 0.7;
  background-color: #000;
}

/* ... Your existing styles ... */

