.update{
    color: #184A45FF !important;
}
.myFormContainer {
    margin-bottom: 1rem;
    max-width: 300px;
  }
  
  .myInput {
    margin-top: 0.5rem;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
  }