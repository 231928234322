.App {
  text-align: center;
  /* margin-top: 50px; */
  
}

body {
  background-color: aliceblue !important;
  /* padding: 0 !important; */
}

.hole-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 55%;
  /* background-color: #000; */
  /* width: 100%; */
 

}

.vertical-line {
  margin-top: 5px; /* Decreased margin-top */
  position: absolute;
  width: 5px;
  left: 10px;
  transform: translateX(-50%);
  background-color: #184a45ff;
}

.text-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: -150%;
}

.left-text {
  position: relative;
  display: inline-flex;

  left: -180px;
  font-size: 0.9rem;
  font-weight: bold;
  color: #184a45ff;
}

.right-text {
  color: #184a45ff;
  font-size: 0.9rem;
  font-weight: bold; /* Added bold */
  position: relative;
  right: -50px;
  bottom: 15px; /* Decreased bottom */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 45px;
  margin-top: -30px; /* Decreased margin-top */
}

.date-text {
  white-space: nowrap;
  writing-mode: vertical-lr;
  text-orientation: upright;
}

/* Your existing styles */

.hole {
  width: 20px;
  height: 20px;
  margin: 15px 0; /* Increased margin-top */
  border: 1px solid #000;
  background-color: #b0b8b4ff;
  border-radius: 50%;
  z-index: 1;
}

/* Your other styles */

.hole.active {
  background-color: #184a45ff;
}

.button {
  margin-top: 5px; /* Decreased margin-top */
  cursor: pointer;
}

.p {
  margin-top: 5px; /* Decreased margin-top */
  color: green;
}

.textt {
  color: green;
  font-weight: bolder;
  position: relative;
  margin-top: 30px; /* Decreased margin-top */
  left: -22px;
}
/* Meter.css */

/* ... (previous styles remain unchanged) */

.scroll-container {
  max-height: 450px;
  overflow-y: auto;
  margin-right: 15px;
}
@media (max-width:300px) {
  .left-text,.right-text{
    font-size: 0.5rem;
  }
  .left-text{
  left: -100px;
  }.right-text{
    right: -10px;
  }
}

/* Style the scrollbar only when overflow happens */
.scroll-container::-webkit-scrollbar {
  width: 10px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #184a45ff; /* Color of the thumb */
  border-radius: 5px; /* Round the corners of the thumb */
}

.scroll-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
}

/* ... (remaining styles remain unchanged) */

.completion-message {
  padding-top: 10px;
}
