.App {
    text-align: center;
    /* background-color: black; */
    height: 60vh;
  }

  body{
    padding: 0;
    /* overflow: hidden; */
    
  }
  html{
    padding: 0;
    /* background-color: black; */
  }
  .admin{
    /* color: white; */
    padding-top: 5%;
    color:#184A45FF ;
  }
 
  
  .home{
    /* background-color: black; */
    height: 100vh;
  
  }
  .head{
    background-color:#184A45FF !important;
    border-radius: 4px !important;
  }
  .card {
    max-width: 400px;
    margin: auto;
    margin-top: 20px;
    padding: 20px; /* Padding inside the card */
    background-color:transparent; /* Light Blue */
    /* border-radius: 10px; */
    box-shadow: #184A45FF;
    /* border: transparent; */
  }
  
  .form-group {
    margin-bottom: 15px;
    width: 110%;
    margin-left: -5%;
    
  }
  
  .form-control {
    padding: 10px;
   
  }
.btnEye{
  background-color: white !important;
  border-color: #C6CCD2 !important;
}
.btnEye:hover{
  color: black  ;
}
  
  /* .btn-primary {
   
    background-color:  #184A45FF !important;
    border-color: #184A45FF;
    width: 110%;
    margin-left: -5%;
  }
  
  .btn-primary:hover {
    background-color: #0056b3; 
    border-color: #0056b3;
  }
   */