.btn2{
    /* background-color: black !important; */
    border: transparent;
    /* position: absolute;
    top: 500px !important; */
    
}

.logg{
   position: relative;
   top: 65px;
   left: 550px;
}
.choosed{
    display: flex;
    justify-content: end;
}
.btn{
    background-color: #184A45FF;
}