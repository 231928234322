
body{
    background-color:whitesmoke;
}
.modal-content{
    /* background-color: black !important; */
    padding: 0 !important;
}
.scroll-container{
    width: 100%;
    height: 100%;
}
.spann{
    color:#184A45FF;
}
.spannn{
    color: #184A45FF;
}
@media (max-width:300px) {
    .scroll-container{
        width: 120%;
}
}
@media (max-width:400px) {
    .scroll-container{
        width: 110%;
}
}
@media (max-width: 576px) {
    .btn5 {
      width: 100%; /* Set the width to 100% on small screens */
    }
  }
  
/* 
.input-container {
    display: flex;
}

.input-container input {
    flex: 1;
    font-size: 1.5em;
    height: 2rem;
}

.input-container button {
    font-size: 1.2em;
    height: 2rem;
    width: 30%;
} */
.input-button{
    margin-left: 5px;
}

.bb{
    overflow: hidden;
}
/* User.css */
/*  */
  
.btn5{
    /* background-color: black !important; */

    /* height: 1%;
    width: 20%;
    margin-bottom: 2% !important; */
    background-color:#184A45FF !important; 
    margin-bottom: 1.5%;
    
}
.btn6{
    background-color: transparent !important;
    color: #184A45FF !important;
margin-top: 0;
}
.truck{
    object-fit: cover;
    width: 100%;
}